import "./App.css";
import { BgcForm } from "./components/BgcForm";

function App() {
  return (
    <div className="App">
      <BgcForm />
    </div>
  );
}

export default App;
